<template>
  <div>
    <b-card>
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p  class="school32 text-center">Тошпулат Норбутабеков<br />(1898 - --)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
           <b-card-text>
          <p class="TextBlack17">
            Акмал Икромов Узбекистон Компартияси V пленумида (1932 йил 13 июнь)
            сузлаган маърузасида Узбекистонда инкилобга кадар узбеклар ораси- да
            атиги биргина олий маълумотли киши булиб, у Тошпулат Норбутабеков
            эканлигини айтганида залдагилар кулишганди. У, асли, тошкентлик
            булиб, 1898 йили Олмазор мах,алласида Турахон домла хонадонида косиб
            оиласида тугилган, эски мактабда укиган, рус-тузем мактабини
            битирган, Тошкент гимназиясини тамомлаган, Петербург университета
            юридик факультетини, шунинг- дек, Ярославль музейини х,ам битирган.
            Куконда нашр этилаётган «Садои Фаргона» рузномасининг 1915 йил 15
            май сах^фасида Ашурали 3oxjiрийнинг «Туркистонда биринчи юрист»
            маколаси Тошпулатбек Норбутабековга багишланади: «Тошпулатбек афанди
            шу йил 13 мартда 18-нчи ракамли фармойиш ила Тошкент шах,- рининг
            судьялик мансабига тайин булиниб, хозирдан тажриба (практика) учун
            Тошкент судебный палатасига котиб (секретарь) вазафа- сини адо килиб
            турадургон булди. Тошпулатбек афанди миллий тилни бир туркистонлик
            шаклий (яъни урисча аралашмас- дан) гапиргани каби укимок, ёзмок,
            тугрисида х,ам бир мулладан яхширок биладир. Тошпулатбек афанди
            Туркистоннинг туб ерли халкидан эллик йилнинг етиштирган мевасидир.
            Шуни х,ам айтиб утмок керакки, Тошпу- латбек афанди шах,римизнинг
            Русия мансаб-дорлари, улуг савдогарлари ораларида эътибо- ри зиёда
            булгонидек, мусулмон савдогарлар ва ён шах,ар наздларида х,ам
            хурмати зиёдадир».Куриниб турибдики, Тошпулатбек Норбутаеков уз
            даврининг етук билимдон кишилари- дан бири булган. 1924 йилда
            тулдирган шахсий варакасидаги «К,айси тилларда эркин гаплаша
            оласиз?» деган саволга: «Форс, рус, турк, козок, ўзбек, француз,
            лотин тилларини биламан», - деб жавоб кайтарган. «Россиянинг кайси
            жой- ларини биласиз?» деган саволга жавобан эса: «Марказни, Москва,
            Петроград, К,рим, Одесса, Ярославль, Кавказорти, Батуми, Боку,
            Тифлис- ларни биламан», - деб ёзган экан. Тошпулатбек
            Норбутабековнинг сунгги такдири нима бул- ганлиги тугрисида
            бирор-бир аник маълумот йук Айтилишича, 1926 йил 25 мартда Самарканд
            шах,ридаги (Узбекистон пойтахти - Р.Ш.) Ленин кучасида Зиёвутдинов,
            Норбутабеков, Исакулов (Ёкубжон - Р.Ш.), Хол- ботиров (Шох,рухмирза
            - Р.Ш.) ва фаргоналик бир киши кофе ичишади. Сухбатда биринчи булиб
            Исакулов ran бошлайди. Шу сух,батда агентнинг хабар беришича,
            Тошпулатбек Норбутабеков шундай деган: «Русларнинг хозирги сиёсати
            асрий миллий низо билан богликдир. Мусулмонларга нисбатан бу нафрат,
            низо узок даврлардан бери юз бермокда. Улар Темурнинг Москвага хужум
            ки- либ киргани ва куплаб русларни улдирганини хотирадан х,еч качон
            чикара олмайдилар. Шу боисдан биз учун бирдан-бир энг ишончли умид
            хорижий давлатлардир, биз улардан ёрдам х,ам кутмогимиз зарур. Агар
            худо хохдаса, улар бизларни бу мустамлакачилар зулмидан халос
            этадилар. Файзулла Хужаев ва Хидиралиевлар гафлатда кола олмайдилар,
            масалани кенгрок куядилар ва масала ижобий маънода х,ал эти- лажак».
            Бу уринда Тошпулатбек Норбутабековнинг 1920 йил 4 сентябрда
            съезднинг 3-мажлисидаги нуткини айнан келтирамиз: Шарк халклари
            мех,наткаш оммасининг ах,воли фа- кат Шарк халклари учунгина эмас,
            балки совет х,окимияти учун х,ам фавкулодда зарурдир. Ушбу 53 га
            якин тиллар ва шеваларга эга бу халкларнинг уз овозига эга булишга
            эх,тиёжи бор, бизнинг делегат сифатидаги бурчимиз у ёки бу
            талабларни хукуматга аник ва равшан такдим килишдан иборат. Бизнинг
            мусулмон халклари ва Шаркнинг бошка халклари Совет хукуматидан бошка
            ҳукуматни хоҳламайдилар деб эълон киламиз. Танлаш им- кони йук: ё
            инглиз капиталистлари, ёки Россия ва бутун жахоннинг мех,наткашлар
            оммаси. Тошпулатбек Норбутабековнинг бу жозиба- ли, нихоятда
            адолатли нутки съезд адлида жуда катта таассурот уйготди. Хатто
            Туркиядан кел- ган Анвар пошшога бу нутк жуда кучли таъсир Килган ва
            унинг большевизм хакидаги фикрлари узгаришига кучли туртки берган.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
